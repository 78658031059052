import React, { Component } from 'react';
import { Admin, Resource, Login } from 'react-admin';
import { DataProvider, AuthClient } from './providers';
import Dashboard from './components/Dashboard.js';
import spanishMessages from '@blackbox-vision/ra-language-spanish';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import CustomLanguageSpanish from './custom-language-spanish';
import { Admin as AdminRole } from './services';
import { makeStyles } from '@material-ui/core/styles';
import AdcLayout from './components/AdcLayout';
import './App.css';
const messages = { ...spanishMessages, ...CustomLanguageSpanish };
const i18nProvider = polyglotI18nProvider(() => messages, 'es');

const adcLoginStyles = makeStyles({
  main: {
    backgroundSize: 'contain',
    backgroundColor: '#00ccff',
    backgroundPosition: '60%'
},
});

const AdcLoginPage = () => (
  <Login
      backgroundImage="/images/adc_digital_logo.png"
      classes={adcLoginStyles()}
  />
);

class App extends Component {
  render() {
    return (
      <Admin
        title='Publi Electoral'
        i18nProvider={i18nProvider}
        dashboard={Dashboard}
        dataProvider={DataProvider}
        authProvider={AuthClient}
        loginPage={AdcLoginPage}
        layout={AdcLayout}
      >
        <Resource
          name="post"
          list={AdminRole.PostList}
          show={AdminRole.PostShow}
          edit={AdminRole.PostEdit}
          options={{ label: 'Publicaciones' }}
        />
        <Resource
          name="account"
          list={AdminRole.AccountList}
          create={AdminRole.AccountCreate}
          edit={AdminRole.AccountEdit}
          options={{ label: 'Cuentas' }}
        />
        <Resource
          name="adv"
          list={AdminRole.AdvertisingList}
          show={AdminRole.AdvertisingShow}
          options={{ label: 'Anuncios' }}
        />
        <Resource
          name="political-party"
          list={AdminRole.PoliticalPartyList}
          create={AdminRole.PoliticalPartyCreate}
          edit={AdminRole.PoliticalPartyEdit}
          options={{label: 'Partidos políticos'}}
        />
        <Resource
          name="social-network"
        />
        <Resource
          name="campaign"
          list={AdminRole.CampaignList}
          create={AdminRole.CampaignCreate}
          edit={AdminRole.CampaignEdit}
          options={{label: 'Campañas'}}
        />
      </Admin>
    );
  }
}

export default App;