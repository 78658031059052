import React, { useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MUButton from '@material-ui/core/Button';
import MUInput from '@material-ui/core/Input';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import PropTypes from 'prop-types';

const CustomPagination = ({ page, perPage, total, setPage, typeData }) => {

  const [currentPage, setCurrentPage] = useState(page? page: 1)
  const totalPages = Math.ceil(total / perPage)

  const styles = {
    selectPageInput: {
      minWidth: '2em',
      maxWidth: '5em',
      margin: '0px 5px 0px 5px',
      textAlignLast: 'center'
    },
    toolbar: {
      justifyContent: 'flex-end'
    },
    totalTypography: {
      margin: '0px 5px 0px 5px'
    }
  }

  const changePage = (page) => {
    switch (page.event) {
      case 'BEFORE_PAGE':
        setPage(page.number > 1 ? page.number - 1 : 1)
        setCurrentPage(page.number > 1 ? page.number - 1 : 1)
        break;
      case 'NEXT_PAGE':
        setPage(page.number === totalPages ? page.number : page.number + 1)
        setCurrentPage(page.number === totalPages ? page.number : page.number + 1)
        break;
      case 'CHOICE_PAGE':
        setPage(page.number)
        setCurrentPage(page.number)
        break;
      default:
    }
  }

  return (
      totalPages > 1 &&
          <Toolbar style={styles.toolbar}>
            <MUButton
              color="primary"
              key="before"
              icon={ChevronLeft}
              onClick={() => changePage({event:'BEFORE_PAGE', number:page})}> Anterior
            </MUButton>
            <Typography variant="caption">{`página`}</Typography>
            <MUInput
              type="number"
              style={styles.selectPageInput}
              inputProps={{ min: 1, max: `${totalPages}` }}
              value={currentPage}
              onChange={(e) => changePage({event: 'CHOICE_PAGE', number: e.target.value})}
            />
            <Typography variant="caption" style={styles.totalTypography}>{`de ${totalPages}`}</Typography>
            <Typography variant="caption">{`/`}</Typography>
            <Typography variant="caption" style={styles.totalTypography}>{`${typeData}: ${total}`}</Typography>
            <MUButton
              color="primary"
              key="next"
              icon={ChevronRight}
              onClick={() => changePage({event: 'NEXT_PAGE', number: page})}> Siguiente
            </MUButton>
          </Toolbar>
  );
}

CustomPagination.propTypes = {
  typeData: PropTypes.string.isRequired,
};

export default (CustomPagination);
