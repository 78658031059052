import * as React from "react";
import { List, Datagrid, TextField, EditButton, Create, 
         SimpleForm, TextInput, Edit } from 'react-admin';

export const PoliticalPartyList = (props) => (
    <List {...props} title="Lista de Partidos Políticos" perPage={25}>
        <Datagrid>
            <TextField source="name" label="Nombre"/>
            <EditButton label="Editar"/>
        </Datagrid>
    </List>
);

export const PoliticalPartyCreate = (props) => (
  <Create {...props} title="Nuevo Partido Político">
      <SimpleForm redirect="list">
          <TextInput source="name" label="Nombre" />
      </SimpleForm>
  </Create>
);

export const PoliticalPartyEdit = (props) => (
  <Edit {...props} title="Editar Partido Político">
      <SimpleForm>
          <TextInput source="name" label="Nombre"/>
      </SimpleForm>
  </Edit>
);