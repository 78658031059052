/**
 * Date formatter to parse ISO dates to UTC.
 */

/**
 * @returns {String} A standardized date (yyyy-MM-dd), to be passed to an <input type="date" />
 */
const dateFormatter = stringDate => {
  const pad = '00';
  const unixTime = Date.parse(stringDate);
  const date = new Date(unixTime);
  const yyyy = date.getUTCFullYear();
  const mm = (date.getUTCMonth() + 1).toString();
  const dd = date.getUTCDate();
  const formattedDate = `${yyyy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`;
  return formattedDate;
};

export default dateFormatter;