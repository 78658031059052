import React from 'react';
import dateFormatter from '../helpers/DateFormatter'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  EditButton,
  Edit,
  required
} from 'react-admin';

export const CampaignList = props => (
  <List {...props} title="Lista de Campañas">
    <Datagrid>
      <TextField label="Nombre" source="name" />
      <DateField label="Inicio" source="startDate" options={{ timeZone: 'UTC' }} />
      <DateField label="Final" source="endDate" options={{ timeZone: 'UTC' }}/>
      <EditButton label="Editar"/>
    </Datagrid>
  </List>
);

export const CampaignCreate = (props) => (
  <Create {...props} title="Crear Campaña">
      <SimpleForm redirect="list">
          <TextInput source="name" label="Nombre" validate={required()}/>
          <DateInput source="startDate" label="Inicio" validate={required()}/>
          <DateInput source="endDate" label="Final" validate={required()}/>
      </SimpleForm>
  </Create>
);

export const CampaignEdit = (props) => (
  <Edit {...props} title="Editar Campaña">
      <SimpleForm redirect="list">
          <TextInput source="name" label="Nombre" validate={required()}/>
          <DateInput source="startDate" label="Inicio" validate={required()} format={dateFormatter}/>
          <DateInput source="endDate" label="Final" validate={required()} format={dateFormatter}/>
      </SimpleForm>
  </Edit>
);