import React from 'react';
import DetailCard from '../components/DetailCard.js';
import { restClient } from 'ra-data-feathers';
import feathersClient from '../feathersClient';
import Grid from '@material-ui/core/Grid';
import papaparse from 'papaparse';
import moment from 'moment';
import CustomPagination from '../components/CustomPagination.js';
import {
  List,
  Show,
  SimpleShowLayout,
  Datagrid,
  TextField,
  DateField,
  TopToolbar,
  ListButton,
  ShowButton,
  Button,
  ExportButton,
  GET_ONE,
  downloadCSV,
} from 'react-admin';

const styles = {
  cardActionStyle: {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
  },
  detailCard: {
    width : 500
  }
};

const exporter = (advs) => {
  const advsForExport = advs.map(adv => {
    const { __v, _id, id, createdAt, updatedAt, userId, ...advsForExport } = adv
    advsForExport.fecha_de_visualizacion = moment(adv.visualizedDate).format('DD/MM/YYYY');
    advsForExport.cuenta = adv.account? adv.account.name : 'Sin cuenta';
    advsForExport.ubicacion_del_usuario = adv.userSelectedLocation? adv.userSelectedLocation : '';
    advsForExport.id_de_publicacion_en_facebook = adv.fbPostId;
    advsForExport.id_de_cuenta_en_facebook = adv.fbAccountId;

    return advsForExport
  });
  const csv = papaparse.unparse({
      data: advsForExport,
      fields: [
        'fecha_de_visualizacion',
        'cuenta',
        'ubicacion_del_usuario',
        'id_de_publicacion_en_facebook',
        'id_de_cuenta_en_facebook'
      ]
  });
  downloadCSV(csv, `anuncios`);
}


const AdvertisingShowTitle = ({ record }) => {
  return <span>Detalle de la Publicación</span>;
};

const AdvertisingShowActions = ({ data }) => (
  <TopToolbar style={styles.cardActionStyle}>
    <ListButton to='/adv' record={data} label="Volver" />
  </TopToolbar>
);

const AdvertisingListActions = ({ resource, data, total, props }) => (
  <TopToolbar {...props}>
    <ExportButton {...props} label="Exportar" exporter={exporter} resource={resource} data={data} maxResults={total} />
  </TopToolbar>
)

export const AdvertisingList = ({ ...props }) => {
  return (
    <List {...props}
      title="Lista de Anuncios"
      perPage={25}
      actions={<AdvertisingListActions {...props}/>}
      pagination={<CustomPagination typeData="anuncios" />}
    >
      <Datagrid>
        <DateField label="Visualizado" source="visualizedDate" locales="es-AR" />
        <TextField label="Cuenta" source="account.name" />
        <TextField label="Ubicacion Seleccionada" source="userSelectedLocation" />
        <ShowButton label="Detalle" target="_blank"/>
      </Datagrid>
    </List>
  );
};

export class AdvertisingShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      post: null
    };
  };

  async componentDidMount() {
    const dataProvider = restClient(feathersClient);
    let adv = await dataProvider(GET_ONE, '/adv', {id: this.props.id})
    let post = await dataProvider(GET_ONE, '/post', {id: adv.data.postId})
    this.setState({
      post: post.data
    })
  }

  render() {
    const { post } = this.state;
    return post !== null ?
      <Show title={<AdvertisingShowTitle />} { ...this.props } actions={<AdvertisingShowActions />}>
        <SimpleShowLayout>
          <Grid container direction="row" justify="space-around">
            <DetailCard title="Mensaje" body={post.text} image={post.image} align="justify"/>
            <div>
              <DetailCard title="Fecha de Publicación" body={post.publicDate} />
              <DetailCard title="Partido Político" body={post.politicalPartyName} />
              <DetailCard title="Cuenta" body={post.account.name} />
              <DetailCard title="Link de Publicación" body={<Button label="Ver Publicación en Facebook" href={post.postLink} target="_blank"/>} />
              <DetailCard title="Visualizaciones por PubliElectoral" body={post.views} onlyNumber={true} />
            </div>
          </Grid>
        </SimpleShowLayout>
      </Show> : null;
  }
}
