import * as React from "react";
import { AppBar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    override: {
        color: 'secondary',
    }
});

const AdcAppBar = props => {
    const classes = useStyles();
    return (
        <AppBar className={classes.override} {...props}>
        </AppBar>
    );
};

export default AdcAppBar;
