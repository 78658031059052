import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DetailCard from './DetailCard';
import { CustomList } from './CustomList';
import Grid from '@material-ui/core/Grid';
import { Title, GET_LIST } from 'react-admin';
import { DataProvider, isUserLogged } from '../providers';

const dataProvider = DataProvider;

const styles = {
  card : {
    minWidth: 500,
  },
  cardContent: {
    minHeight: '40em',
  }
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null
    };
  };

  async componentDidMount() {
    if (isUserLogged()) {
      const advertisingResponse = await
      dataProvider(GET_LIST, 'account', { filter: { dashboard: true } });
      let data = await advertisingResponse.data;

      this.setState({
        data: data,
      });
    }
  }

  render() {
    const { data } = this.state;
    return data?
    <Card styles={styles.card}>
        <Title title='PubliElectoral' />
        <CardContent style={styles.cardContent}>
          {
            data.map(partyData => (
              <Grid container direction="row" justify="space-around" key={`${partyData.politicalPartyName}-container-info`}>
                <DetailCard
                  raised={true}
                  key={`${partyData.politicalPartyName}-card-info`}
                  title={partyData.politicalPartyName}
                  body={
                    <CustomList
                      key={`${partyData.politicalPartyName}-items-list`}
                      items={partyData.accountNames} />} />
                <DetailCard
                  raised={true}
                  key={`${partyData.politicalPartyName}-card-posts-count`}
                  title="Publicaciones"
                  body={partyData.postCount}
                  onlyNumber={true}
                />
                <DetailCard
                  raised={true}
                  key={`${partyData.politicalPartyName}-card-advs-count`}
                  title="Anuncios"
                  body={partyData.advCount? partyData.advCount : 0 }
                  onlyNumber={true}
                />
              </Grid>
            ))
          }
        </CardContent>
      </Card> : null;
  };
};

export default Dashboard;
