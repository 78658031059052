import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core';

const styles = {
  card: {
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 40px 10px 40px',
    minWidth: '300px',
    maxWidth: '300px',
    whiteSpace: 'pre-line',
    marginBottom: 0
  },
  imageCard: {
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 40px 10px 40px',
    maxWidth: '400px',
    height: '100%',
    marginBottom: 0,
  }
};

const DetailCard = props => {
  const cardStyle = props.image ? styles.imageCard : styles.card;
  return (
    <Card
      raised={props.raised !== undefined ? props.raised : false}
      style={cardStyle}
    >
      <CardContent>
        <Grid container direction="column" align="center" justify="center">
          <Typography
            component="span"
            variant="subtitle1"
            align="center"
            justify="center"
          > {props.title}
          </Typography>
          <hr style={{width: '100%', marginTop: '0.2em'}}/>
          {props.editMode ? 
            <TextField value={props.body} multiline rowsMax="20"/> :
            <Typography
              component="span"
              variant={props.onlyNumber ? 'h4' : 'body2'}
              align={props.align ? props.align : 'center'}
              justify={props.justify ? props.justify : 'center'}
              paragraph={true}
            > {props.body}
            </Typography>
          }
        </Grid>
      </CardContent>
      {props.image ?
        <CardMedia
          component="img"
          alt={props.image}
          height="75%"
          image={props.image}
        /> : null
      }
    </Card>
  );
};

DetailCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DetailCard);
