import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  UrlField,
  TextInput,
  Create,
  SimpleForm, 
  ReferenceInput, 
  SelectInput,
  Edit,
  EditButton, 
  regex,
  required
} from 'react-admin';

  const urlRegex = /^(https?):\/\/[^\s$.?#].[^\s]*$/
  const validateUrl = [required(), regex(urlRegex, "Url no valida.")]


export const AccountList = props => (
  <List {...props} title="Cuentas a Monitorear">
    <Datagrid>
      <TextField label="Nombre" source="name" />
      <TextField label="Partido" source="politicalPartyName" />
      <TextField label="ID de Red Social" source="fbAccountId" />
      <TextField label="Red Social" source="socialNetworkName"  />
      <UrlField source="link" target="_blank"/>
      <EditButton label="Editar"/>
    </Datagrid>
  </List>
);

export const AccountCreate = (props) => (
  <Create {...props} title="Nueva cuenta">
    <SimpleForm redirect="list">
      <TextInput label="Nombre" source="name" validate={required()} />
      <ReferenceInput label="Partido" source="politicalParty" reference="political-party">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput label="ID de Red Social" source="fbAccountId" />
      <ReferenceInput label="Red Social" source="socialNetwork" reference="social-network">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="link"  type="url" validate={validateUrl} required target="_blank"/>
    </SimpleForm>
  </Create>
);

export const AccountEdit = (props) => (
  <Edit {...props} title="Nueva cuenta">
    <SimpleForm>
      <TextInput label="Nombre" source="name" />
      <ReferenceInput label="Partido" source="politicalParty" reference="political-party">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput label="ID de Red Social" source="fbAccountId" />
      <ReferenceInput label="Red Social" source="socialNetwork" reference="social-network">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="link"  validate={validateUrl} target="_blank"/>
    </SimpleForm>
  </Edit>
);