import React from 'react';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

export class CustomList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  };

  render() {
    const { items, button } = this.props;
    return (
      <List style={{height: 350, overflow: 'scroll', overflowX: 'auto', overflowY: 'auto'}}>
        {
          items.map(item => (
            <div key={`item-list-div-${item}`}>
              <ListItem
                key={`item-list-${item}`}
                button={!button ? false : true}
              >
                <ListItemText
                  key={`item-text-${item}`}
                  primary={
                    <Typography component="span" variant='body2' align='center'>
                      {`${item}`}
                    </Typography>
                  }
                />
              </ListItem>
              <Divider />
            </div>
          ))
        }
      </List>
    );
  };
};
