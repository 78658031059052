import React from 'react';
import { Fragment } from 'react';
import DetailCard from '../components/DetailCard.js';
import { restClient } from 'ra-data-feathers';
import feathersClient from '../feathersClient';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import papaparse from 'papaparse';
import CustomPagination from '../components/CustomPagination.js';
import { useForm } from 'react-final-form';
import dateFormatter from '../helpers/DateFormatter';
import POST from '../constants/post.type';
import CardContent from '@material-ui/core/CardContent';
import {
  List,
  Show,
  SimpleShowLayout,
  Datagrid,
  TextField,
  ShowButton,
  TopToolbar,
  ListButton,
  DateInput,
  Filter,
  Button,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  GET_ONE,
  GET_LIST,
  downloadCSV,
  ExportButton,
  FormDataConsumer,
  Edit,
  SimpleForm,
  EditButton,
  TextInput,
} from 'react-admin';

const styles = {
  cardActionStyle: {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
  },
  detailCard: {
    width : 500
  },
  campaignFilter: {
    maxHeight: 52,
    marginRight: 16
  }
};

const PostShowTitle = ({ record }) => {
  return <span>Detalle de la Publicación</span>;
};

const exporter = posts => {
    const postsForExport = posts.map(post => {
        const { _id, id, author, type, createdAt, updatedAt, __v, accountName, ...postForExport } = post; // omit backlinks and author
        postForExport.fecha_de_publicacion = post.publicDate;
        postForExport.cuenta = post.account? post.account.name : ''; // add a field
        postForExport.tipo = post.type;
        postForExport.texto = post.text;
        postForExport.link_de_publicacion = post.postLink;
        postForExport.link_de_imagen = post.image;
        postForExport.id_de_publicacion_en_facebook = post.fbPostId;
        postForExport.id_de_cuenta_en_facebook = post.fbAccountId;
        return postForExport;
    });
    const csv = papaparse.unparse({
        data: postsForExport,
        fields: [
          'fecha_de_publicacion',
          'tipo',
          'cuenta',
          'texto',
          'link_de_publicacion',
          'link_de_imagen',
          'id_de_publicacion_en_facebook',
          'id_de_cuenta_en_facebook'
        ]
    });
    downloadCSV(csv, `publicaciones`); // download as 'posts.csv` file
};


const campaignDateValues = (value, form, campaigns) => {
  if (campaigns && value !== '') {
    let currentCampaign = campaigns.find(campaign => campaign._id === value);
    form.change('since', dateFormatter(currentCampaign.startDate));
    form.change('until', dateFormatter(currentCampaign.endDate));
  }
  if (value === '') {
    form.change('since', null);
    form.change('until', null);

  }
}

const clearCampaign = (form) => {
  form.change('campaign', null);
}
const CampaignFilter = ({ formData, ...rest }) => {
  const form = useForm();
  const campaigns = rest.campaigns;
  return (
      <Fragment>
        <DateInput label="Desde" source="since" type="date" allowempty="true"
                   alwaysOn style={styles.campaignFilter}
                   onChange={value => clearCampaign(form)}/>
        <DateInput label="Hasta" source="until" type="date" allowempty="true"
                   alwaysOn style={styles.campaignFilter}
                   onChange={value => clearCampaign(form)}/>
        <ReferenceInput label="Campaña" source="campaign" reference="campaign"
                        onChange={(selected) => campaignDateValues(selected.target.value, form, campaigns)}
                        allowempty="true"  alwaysOn {...rest}>
          <SelectInput optionText="name" optionValue="id"/>
        </ReferenceInput >
      </Fragment>
  );
};

export class PostFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaigns: null
    };
  };

  componentDidMount() {
    const dataProvider = restClient(feathersClient);
    dataProvider(GET_LIST, '/campaign', { filter: {} }).then((response) => {
      this.setState({
        campaigns: response.data
      });
    });
  };

  render() {
    const { campaigns } = this.state;
    return <Filter {...this.props} style={{ maxHeight: 64 }}>
    <FormDataConsumer alwaysOn>
       {formDataProps => (
          <CampaignFilter campaigns={campaigns} {...formDataProps} />
        )}
    </FormDataConsumer>
    <SelectInput label="Tipo" source="type" choices={POST.TYPE} alwaysOn/>
    <ReferenceInput label="Partido" source="politicalParty" reference="political-party" allowempty="true" alwaysOn>
      <SelectInput optionText="name"/>
    </ReferenceInput>
    <ReferenceInput label="Cuenta" source="account" reference="account" allowempty="true" alwaysOn >
      <SelectInput optionText="name"/>
    </ReferenceInput>
  </Filter>
  }
}

const postRowStyle = (record, index) => ({
  height: 120,
});

const PostListActions = ({ basePath, data, resource, total, filters, filterValues, ...props }) => {
  return (
    <TopToolbar filters={filters}>
      <ExportButton
        label="Exportar"
        exporter={exporter}
        resource={resource}
        data={data}
        maxResults={total}
        filter={filterValues}
      />
    </TopToolbar>
  )
}

export const PostList = props => {
  return (
    <List {...props}
        title="Lista de Publicaciones"
        perPage={25}
        filters={<PostFilter/>}
        actions={<PostListActions {...props}/>}
        pagination={<CustomPagination typeData="publicaciones" {...props}/>}
    >
      <Datagrid rowStyle={postRowStyle}>
        <TextField label="Fecha" source="publicDate" style={{fontSize: 16}} />
        <TextField label="Tipo" source="type" style={{fontSize: 16}}/>
        <ReferenceField label="Partido Político" source="account.politicalParty" reference="political-party" link={false} >
          <TextField source="name" style={{fontSize: 16}} />
        </ReferenceField>
        <ReferenceField label="Cuenta" source="account._id" reference="account" link={false} >
          <TextField source="name" style={{fontSize: 16}} />
        </ReferenceField>
        <TextField label="Mensaje" source="text" style={{fontSize: 16, height: '4em', overflow: 'hidden'}} />
        <ShowButton label="Detalles" target="_blank"/>
        <EditButton label="Editar" target="_blank"/>
      </Datagrid>
    </List>
  );
};

const PostShowActions = ({ basePath, data, resource }) => (
  <TopToolbar style={styles.cardActionStyle}>
    <ListButton to={basePath} label="Volver" />
  </TopToolbar>
);

export class PostShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      post: null
    };
  };

  componentDidMount() {
    const dataProvider = restClient(feathersClient);
    dataProvider(GET_ONE, '/post', {id: this.props.id}).then((response) => {
      this.setState({
        post: response.data
      });
    });
  };

  render() {
    const { post } = this.state;
    return post !== null ?
      <Show title={<PostShowTitle />} {...this.props} actions={<PostShowActions />}>
        <SimpleShowLayout>
          <Grid container direction="row" justify="space-around">
            <DetailCard
              title="Mensaje"
              body={post.text}
              image={post.image}
              align="justify"
            />
            <div>
              <DetailCard title="Fecha de Publicación" body={post.publicDate} />
              <DetailCard title="Tipo" body={post.type} />
              <DetailCard title="Partido Político" body={post.politicalPartyName} />
              <DetailCard title="Cuenta" body={post.account.name} />
              <DetailCard title="Link de Publicación" body={<Button label="Ver Publicación en Facebook" href={post.postLink} target="_blank"/>} />
              <DetailCard title="Visualizaciones por PubliElectoral" body={post.views} onlyNumber={true} />
            </div>
          </Grid>
        </SimpleShowLayout>
      </Show> : null;
  }
}

export class PostEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      post: null
    };
  };

  componentDidMount() {
    const dataProvider = restClient(feathersClient);
    dataProvider(GET_ONE, '/post', {id: this.props.id}).then((response) => {
      this.setState({
        post: response.data
      });
    });
  };

  render() {
    const { post } = this.state;
    return post !== null ?
    <Edit title="Editar Post" {...this.props}>
      <SimpleForm>
        <CardContent style={{width: 'inherit'}}>
            <Box display="flex" flexDirection={{xs: 'column', sm: 'column', md: 'row'}}>
              <Box display="flex" mr="1em" >
                <DetailCard title="Mensaje" body={post.text} image={post.image} 
                            align="justify" editMode='true'/>
              </Box>
              <Box display="flex" mr="1em" flexDirection='column'>
                <TextInput label="Link" source="postLink" fullWidth={true}/>
                <ReferenceInput label="Cuenta" source="account._id" reference="account">
                  <SelectInput optionText="name" optionValue="_id" />
                </ReferenceInput>
                { post.text ? <TextInput label="% Electoral Texto" source="electoralTextRate" initialValue="0" disabled/> :
                               null
                }
                { post.image ? <TextInput label="% Electoral Imagen" source="electoralImageRatestLink" initialValue="0" disabled/> :
                               null
                }
                <SelectInput label="Tipo" source="type" choices={POST.TYPE}/>
              </Box>
            </Box>
        </CardContent>
      </SimpleForm>
  </Edit> : null;
  }
}

export default PostEdit;