import {
  PostList,
  PostShow,
  PostEdit,
} from './Post.js';

import {
  AdvertisingList,
  AdvertisingShow
} from './Advertising.js';

import {
  AccountList,
  AccountCreate,
  AccountEdit,
} from './Account.js';

import {
  PoliticalPartyList,
  PoliticalPartyCreate,
  PoliticalPartyEdit,
} from './PoliticalParty';

import {
  CampaignList,
  CampaignCreate,
  CampaignEdit,
} from './Campaign'


const Admin = {
  PostList,
  PostShow,
  PostEdit,
  AdvertisingList,
  AdvertisingShow,
  AccountList,
  AccountCreate,
  AccountEdit,
  PoliticalPartyList,
  PoliticalPartyCreate,
  PoliticalPartyEdit,
  CampaignList,
  CampaignCreate,
  CampaignEdit,
};

export {
  Admin
};
